// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-108-classroom-teaching-strategies-tsx": () => import("./../../../src/pages/108-classroom-teaching-strategies.tsx" /* webpackChunkName: "component---src-pages-108-classroom-teaching-strategies-tsx" */),
  "component---src-pages-12-ways-to-email-like-a-boss-tsx": () => import("./../../../src/pages/12-ways-to-email-like-a-boss.tsx" /* webpackChunkName: "component---src-pages-12-ways-to-email-like-a-boss-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-49-tsx": () => import("./../../../src/pages/49.tsx" /* webpackChunkName: "component---src-pages-49-tsx" */),
  "component---src-pages-ai-credits-tsx": () => import("./../../../src/pages/ai-credits.tsx" /* webpackChunkName: "component---src-pages-ai-credits-tsx" */),
  "component---src-pages-animate-tutorial-tsx": () => import("./../../../src/pages/animate-tutorial.tsx" /* webpackChunkName: "component---src-pages-animate-tutorial-tsx" */),
  "component---src-pages-apikey-tsx": () => import("./../../../src/pages/apikey.tsx" /* webpackChunkName: "component---src-pages-apikey-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-cookiefirsttest-tsx": () => import("./../../../src/pages/cookiefirsttest.tsx" /* webpackChunkName: "component---src-pages-cookiefirsttest-tsx" */),
  "component---src-pages-deal-tsx": () => import("./../../../src/pages/deal.tsx" /* webpackChunkName: "component---src-pages-deal-tsx" */),
  "component---src-pages-download-osx-ai-beta-tsx": () => import("./../../../src/pages/download/osx-ai-beta.tsx" /* webpackChunkName: "component---src-pages-download-osx-ai-beta-tsx" */),
  "component---src-pages-download-osx-tsx": () => import("./../../../src/pages/download/osx.tsx" /* webpackChunkName: "component---src-pages-download-osx-tsx" */),
  "component---src-pages-download-osxm-1-ai-beta-tsx": () => import("./../../../src/pages/download/osxm1-ai-beta.tsx" /* webpackChunkName: "component---src-pages-download-osxm-1-ai-beta-tsx" */),
  "component---src-pages-download-osxm-1-tsx": () => import("./../../../src/pages/download/osxm1.tsx" /* webpackChunkName: "component---src-pages-download-osxm-1-tsx" */),
  "component---src-pages-download-vip-osx-tsx": () => import("./../../../src/pages/download/vip-osx.tsx" /* webpackChunkName: "component---src-pages-download-vip-osx-tsx" */),
  "component---src-pages-download-vip-osxm-1-tsx": () => import("./../../../src/pages/download/vip-osxm1.tsx" /* webpackChunkName: "component---src-pages-download-vip-osxm-1-tsx" */),
  "component---src-pages-download-vip-win-tsx": () => import("./../../../src/pages/download/vip-win.tsx" /* webpackChunkName: "component---src-pages-download-vip-win-tsx" */),
  "component---src-pages-download-win-ai-beta-tsx": () => import("./../../../src/pages/download/win-ai-beta.tsx" /* webpackChunkName: "component---src-pages-download-win-ai-beta-tsx" */),
  "component---src-pages-download-win-tsx": () => import("./../../../src/pages/download/win.tsx" /* webpackChunkName: "component---src-pages-download-win-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-get-tsx": () => import("./../../../src/pages/get.tsx" /* webpackChunkName: "component---src-pages-get-tsx" */),
  "component---src-pages-how-the-top-20-crush-tsx": () => import("./../../../src/pages/how-the-top-20-crush.tsx" /* webpackChunkName: "component---src-pages-how-the-top-20-crush-tsx" */),
  "component---src-pages-index-old-06052024-tsx": () => import("./../../../src/pages/index-old06052024.tsx" /* webpackChunkName: "component---src-pages-index-old-06052024-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lifetime-deal-tsx": () => import("./../../../src/pages/lifetime-deal.tsx" /* webpackChunkName: "component---src-pages-lifetime-deal-tsx" */),
  "component---src-pages-lifetime-deals-tsx": () => import("./../../../src/pages/lifetime-deals.tsx" /* webpackChunkName: "component---src-pages-lifetime-deals-tsx" */),
  "component---src-pages-lifetime-price-tsx": () => import("./../../../src/pages/lifetime-price.tsx" /* webpackChunkName: "component---src-pages-lifetime-price-tsx" */),
  "component---src-pages-lifetime-tsx": () => import("./../../../src/pages/lifetime.tsx" /* webpackChunkName: "component---src-pages-lifetime-tsx" */),
  "component---src-pages-lifetime-upgrade-sketchwow-tsx": () => import("./../../../src/pages/lifetime-upgrade-sketchwow.tsx" /* webpackChunkName: "component---src-pages-lifetime-upgrade-sketchwow-tsx" */),
  "component---src-pages-members-uc-tsx": () => import("./../../../src/pages/members-uc.tsx" /* webpackChunkName: "component---src-pages-members-uc-tsx" */),
  "component---src-pages-notfound-tsx": () => import("./../../../src/pages/notfound.tsx" /* webpackChunkName: "component---src-pages-notfound-tsx" */),
  "component---src-pages-offer-tsx": () => import("./../../../src/pages/offer.tsx" /* webpackChunkName: "component---src-pages-offer-tsx" */),
  "component---src-pages-open-source-licenses-tsx": () => import("./../../../src/pages/open-source-licenses.tsx" /* webpackChunkName: "component---src-pages-open-source-licenses-tsx" */),
  "component---src-pages-pricing-old-06052024-tsx": () => import("./../../../src/pages/pricing-old06052024.tsx" /* webpackChunkName: "component---src-pages-pricing-old-06052024-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-pro-deal-tsx": () => import("./../../../src/pages/pro-deal.tsx" /* webpackChunkName: "component---src-pages-pro-deal-tsx" */),
  "component---src-pages-promo-tsx": () => import("./../../../src/pages/promo.tsx" /* webpackChunkName: "component---src-pages-promo-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-sale-min-tsx": () => import("./../../../src/pages/sale-min.tsx" /* webpackChunkName: "component---src-pages-sale-min-tsx" */),
  "component---src-pages-sale-test-tsx": () => import("./../../../src/pages/sale-test.tsx" /* webpackChunkName: "component---src-pages-sale-test-tsx" */),
  "component---src-pages-sale-tsx": () => import("./../../../src/pages/sale.tsx" /* webpackChunkName: "component---src-pages-sale-tsx" */),
  "component---src-pages-save-test-tsx": () => import("./../../../src/pages/save-test.tsx" /* webpackChunkName: "component---src-pages-save-test-tsx" */),
  "component---src-pages-save-tsx": () => import("./../../../src/pages/save.tsx" /* webpackChunkName: "component---src-pages-save-tsx" */),
  "component---src-pages-saver-tsx": () => import("./../../../src/pages/saver.tsx" /* webpackChunkName: "component---src-pages-saver-tsx" */),
  "component---src-pages-saves-tsx": () => import("./../../../src/pages/saves.tsx" /* webpackChunkName: "component---src-pages-saves-tsx" */),
  "component---src-pages-send-review-tsx": () => import("./../../../src/pages/send-review.tsx" /* webpackChunkName: "component---src-pages-send-review-tsx" */),
  "component---src-pages-special-tsx": () => import("./../../../src/pages/special.tsx" /* webpackChunkName: "component---src-pages-special-tsx" */),
  "component---src-pages-submit-review-tsx": () => import("./../../../src/pages/submit-review.tsx" /* webpackChunkName: "component---src-pages-submit-review-tsx" */),
  "component---src-pages-terms-service-tsx": () => import("./../../../src/pages/terms-service.tsx" /* webpackChunkName: "component---src-pages-terms-service-tsx" */),
  "component---src-pages-trial-tsx": () => import("./../../../src/pages/trial.tsx" /* webpackChunkName: "component---src-pages-trial-tsx" */),
  "component---src-pages-tutorials-tsx": () => import("./../../../src/pages/tutorials.tsx" /* webpackChunkName: "component---src-pages-tutorials-tsx" */),
  "component---src-pages-yt-deal-tsx": () => import("./../../../src/pages/yt-deal.tsx" /* webpackChunkName: "component---src-pages-yt-deal-tsx" */)
}

